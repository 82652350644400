import { Link } from 'gatsby'
import React from 'react'
import { titleCase } from "title-case";

// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/

function namesToStr(nameList){
    if(nameList){
        var tot = "";
        for(var i = 0; i < nameList.length; i++){
            var e = nameList[i]
            if(tot.length > 0){
                tot += ', '
            }
            if(e.startsWith("Hall, Chris") || (e.startsWith("Chris") && e.endsWith("Hall"))){
                tot += "<u>"+e+"</u>"
            }else{
                tot += e
            }
        }
        return tot;
    } else {
        return ""
    }
}
var PubEntry = ( props ) => {
    return (
        <div>
            <span dangerouslySetInnerHTML={{__html:namesToStr(props.authors)}}></span>
            {props.authors ? '. ' : ""}
            <b>{titleCase(props.title ? '"'+props.title+'" ' : "")}</b>
            <i>{titleCase(props.journal ? props.journal : "")}</i>
            {props.vol ? ', vol. '+props.vol : ""}
            {props.num ? ', no. '+props.num : ""}
            {props.num ? ', '+props.year : ""}
            {props.pp ? ', pp. '+props.pp : ""}
            . <Link to={props.url}>{props.url}</Link>
        </div>
    )
}
export default PubEntry