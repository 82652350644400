import * as React from "react"
import { Link } from "gatsby"


// Comonent library
import 'semantic-ui-css/semantic.min.css' // Semantic UI component library: https://react.semantic-ui.com/
//// see https://react.semantic-ui.com/layouts/fixed-menu/ 
//// and https://github.com/Semantic-Org/Semantic-UI-React/blob/master/docs/src/layouts/FixedMenuLayout.js for example
import { 
  Container,
  // Divider,
  // Dropdown,
  // Grid,
  Header,
  Image,
  List,
  // Menu,
  // Segment,
  // Checkbox,
  Accordion,
  Icon,
  Card,
} from 'semantic-ui-react'

import HeaderBar from '../components/HeaderBar.js'
import FooterBar from '../components/FooterBar.js'
import PubEntry from "../components/PubEntry.js"

// styles
const cardstyle = {padding:'1em'}


// data
const articles = [
  {
    "authors": ["Xie, Qiaoyun", "Huete, Alfredo", "Hall, Christopher", "Medlyn, Belinda", "Power, Sally", "Davies, Janet", "Medek, Danielle", "Beggs, Paul"],
    "title": "Satellite-observed shifts in C3/C4 abundance in Australian grasslands are associated with rainfall patterns",
    "journal": "Remote Sensing of Environment",
    "vol": 273,
    "num": null,
    "year": 2022,
    "pp": "112983",
    "url": "https://doi.org/10.1016/j.rse.2022.112983"
  },
  {
    "authors": ["Xie, Qiaoyun", "Cleverly, Jamie", "Moore, Caitlin", "Ding, Yanling", "Hall, Christopher", "Ma, Xuanlong", "Brown, Luke", "Wang, Cong", "Beringer, Jason", "Prober, Suzanne", "Macfarlane, Craig", "Meyer, Wayne", "Yin, Gaofei", "Huete, Alfredo"],
    "title": "Land surface phenology retrievals for arid and semi-arid ecosystems",
    "journal": "ISPRS Journal of Photogrammetry and Remote Sensing",
    "vol": 185,
    "num": null,
    "year": 2022,
    "pp": "129-145",
    "url": "https://doi.org/10.1016/j.isprsjprs.2022.01.017"
  },
  {
    "authors": ["Herdean, Andrei", "Hall, Christopher", "Pham, LL", "Miller, SM", "Pernice, M", "Ralph, P"],
    "title": "Action Spectra and Excitation Emission Matrices reveal the broad range of usable photosynthetic active radiation for Phaeodactylum tricornutum",
    "journal": "Biochimica et Biophysica acta. Bioenergetics",
    "vol": 1862,
    "num": 9,
    "year": 2021,
    "pp": "148461",
    "url": "https://doi.org/10.1016/j.bbabio.2021.148461"
  },
  {
    "authors": ["Sutherland, Donna L", "McCauley, Janice", "Labeeuw, Leen", "Ray, Parijat", "Kuzhiumparambil, Unnikrishnan", "Hall, Christopher", "Doblin, Martina", "Nguyen, Luong N", "Ralph, Peter J"],
    "title": "How Microalgal Biotechnology Can Assist with the UN Sustainable Development Goals for Natural Resource Management",
    "journal": "Current Research in Environmental Sustainability",
    "vol": 3,
    "num": null,
    "year": 2021,
    "pp": "100050",
    "url": "https://doi.org/10.1016/j.crsust.2021.100050"
  },
  {
    "authors": ["Yanling Ding", "Hongyan Zhang", "Zhongqiang Wang", "Qiaoyun Xie", "Yeqiao Wang", "Lin Liu", "Christopher C. Hall"],
    "title": "A Comparison of Estimating Crop Residue Cover from Sentinel-2 Data Using Empirical Regressions and Machine Learning Methods",
    "journal": "Remote Sensing",
    "vol": 12,
    "num": 9,
    "year": 2020,
    "pp": 1470,
    "url": "https://doi.org/10.3390/rs12091470"
  },
  {
    "authors": ["Fabris Michele", "Abbriano Raffaela M.", "Pernice Mathieu", "Sutherland Donna L.", "Commault Audrey S.", "Hall Christopher C.", "Labeeuw Leen", "McCauley Janice I.", "Kuzhiuparambil Unnikrishnan", "Ray Parijat", "Kahlke Tim", "Ralph Peter J."],
    "title": "Emerging Technologies in Algal Biotechnology: Toward the Establishment of a Sustainable, Algae-Based Bioeconomy",
    "journal": "Frontiers in Plant Science",
    "vol": 11,
    "num": null,
    "year": 2020,
    "pp": "279",
    "url": "https://doi.org/10.3389/fpls.2020.00279"
  },
  {
    "authors": ["Qiaoyun Xie", "Jadu Dash", "Alfredo Huete", "Aihui Jiang", "Gaofei Yin", "Yanling Ding", "Dailiang Peng", "Christopher C. Hall", "Luke Brown", "Yue Shi", "Huichun Ye", "Yingying Dong", "Wenjiang Huang"],
    "title": "Retrieval of crop biophysical parameters from Sentinel-2 remote sensing imagery",
    "journal": "International Journal of Applied Earth Observation and Geoinformation",
    "vol": 80,
    "num": null,
    "year": 2019,
    "pp": "187-195",
    "url": "https://dx.doi.org/10.1016/j.jag.2019.04.019"
  },
  {
    "authors": ["Cruz, Jeffrey A", "Savage, Linda J", "Zegarac, Robert", "Hall, Christopher C", "Satoh-Cruz, Mio", "Davis, Geoffry A", "Kovac, William Kent", "Chen, Jin", "Kramer, David M"],
    "title": "Dynamic Environmental Photosynthetic Imaging Reveals Emergent Phenotypes.",
    "journal": "Cell Systems",
    "vol": 2,
    "num": 6,
    "year": 2016,
    "pp": "365–77",
    "url": "https://doi.org/10.1016/j.cels.2016.06.001"
  },
  {
    "authors": ["Lucker, Ben F.", "Hall, Christopher C.", "Zegarac, Robert", "Kramer, David M."],
    "title": "The environmental photobioreactor (ePBR): An algal culturing platform for simulating dynamic natural environments",
    "journal": "Algal Research",
    "vol": 6,
    "num": null,
    "year": 2014,
    "pp": "242-249",
    "url": "https://doi.org/10.1016/j.algal.2013.12.007"
  },
]

// markup
export default class MediaPage extends React.Component {
  // handle accordions
  state = { hideStates: {} }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { hideStates } = this.state
    if(hideStates[index] === undefined){
      hideStates[index] = true;
    } else {
      hideStates[index] = !hideStates[index];
    }
    this.setState({ hideStates: hideStates });
  };
  //
  render() {
    const { hideStates } = this.state
    return (
    <div>
    <header role="banner">
        <HeaderBar />
      </header>
      <main role="main">

      <Container text style={{ marginTop: '7em' }}>
        <Header as='h1'>Media Appearances</Header>
        <Accordion>
          <Accordion.Title
            active={!hideStates[0]}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name='dropdown' />{hideStates[0] ? "Show" : "Hide"}
          </Accordion.Title>
          <Accordion.Content active={!hideStates[0]}>
            
            <Card.Group>
              <Card style={cardstyle}>
                <Card.Content style={{textAlign:'center'}}>
                  <Image src='/images/i-40-sign-square.jpg' circular size='small' />
                </Card.Content>
                <Card.Header style={{textAlign:'center'}}>Video | Industry-4.0</Card.Header>
                <Card.Meta style={{textAlign:'center'}}>UTS TechLab, 2021</Card.Meta>
                <Card.Description>
                  <p>Watch Professor Jochen and me introduce the new <Link to="https://techlab.uts.edu.au/lab/industry-4-0-testlab/">Industry-4.0 Algae Test Lab</Link> at the University of Technology Sydney TechLab.</p>
                </Card.Description>
                <Card.Content extra>
                  <Link to='https://youtu.be/5qimOVqRRjA'>Watch on YouTube</Link>
                </Card.Content>
              </Card>
              <Card style={cardstyle}>
                <Card.Content style={{textAlign:'center'}}>
                  <Image src='/images/History-of-Oxygen-Title-Slide_sm.jpg' size='small' />
                </Card.Content>
                <Card.Header style={{textAlign:'center'}}>Public Talk | History of Oxygen</Card.Header>
                <Card.Meta style={{textAlign:'center'}}>Sydney Science Festival, 2018</Card.Meta>
                <Card.Description>
                  <p>I gave a public science talk for the 2018 Sydney Science Festival at the Barry O’Keefe library, as well as a live radio interview with the ABC.</p>
                </Card.Description>
                <Card.Content extra>
                  <Link to='https://www.abc.net.au/radio/sydney/programs/breakfast/history-of-oxygen/10105330'>Live radio interview</Link>
                </Card.Content>
              </Card>
            </Card.Group>

          </Accordion.Content>
        </Accordion>
        <Header as='h1'>Publications</Header>
        <Header as='h2'>Books</Header>
          <Accordion>
            <Accordion.Title
              active={!hideStates[1]}
              index={1}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />{hideStates[1] ? "Show" : "Hide"}
            </Accordion.Title>
            <Accordion.Content active={!hideStates[1]}>
            <List as='ul'>
              <List.Item>
                <Header as='h3'>Photosynthesis and Hyperoxia</Header>
                <i>by <u>Christopher C. Hall</u> (2017)</i><br/>
                ProQuest LLC, Ann Arbor Michigan<br/>
                <Link to='https://www.proquest.com/openview/74527c811b956b9560b8b3a472587cfd/1'>https://www.proquest.com/openview/74527c811b956b9560b8b3a472587cfd/1</Link>
              </List.Item>
            </List>
            </Accordion.Content>
          </Accordion>
        <Header as='h2'>Scientific Journal Articles</Header>
          <Accordion>
            <Accordion.Title
              active={!hideStates[2]}
              index={2}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />{hideStates[2] ? "Show" : "Hide"}
            </Accordion.Title>
            <Accordion.Content active={!hideStates[2]}>
              <List as='ul'>
              {articles.map((e) => {return (<List.Item><PubEntry {...e} /></List.Item>)})}
              </List>
            </Accordion.Content>
          </Accordion>
        <Header as='h2'>Patents</Header>
          <Accordion>
            <Accordion.Title
              active={!hideStates[3]}
              index={3}
              onClick={this.handleClick}
            >
              <Icon name='dropdown' />{hideStates[3] ? "Show" : "Hide"}
            </Accordion.Title>
            <Accordion.Content active={!hideStates[3]}>
            <List as='ul'>
              <List.Item>
                <Header as='h3'>Plant phenometrics systems and methods and devices related thereto</Header>
                <i>David Kramer, Jeffrey Cruz, <u>Christopher Hall</u>, William Kent Kovac, Robert Zegarac (2016)</i><br/>
                US Patent # US9429521B2<br/>
                <Link to='https://patents.google.com/patent/US9429521B2/en'>https://patents.google.com/patent/US9429521B2/en</Link>
              </List.Item>
              <List.Item>
                <Header as='h3'>Environmental photobioreactor array (EPBRA) systems and apparatus related thereto</Header>
                <i>David Kramer, Robert Zegarac, Ben F. Lucker, <u>Christopher Hall</u>, Casey Abernathy, Joel Carpenter, Jeffrey Cruz (2011)</i><br/>
                US Patent # US9816065B2<br/>
                <Link to='https://patents.google.com/patent/US9816065B2/en'>https://patents.google.com/patent/US9816065B2/en</Link>
              </List.Item>
            </List>
            </Accordion.Content>
          </Accordion>
        
      </Container>

      
      </main>
      <footer role="banner">
        <FooterBar />
      </footer>
      </div>
    )
  }
}
